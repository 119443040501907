<template>
  <div class="container">
    <div class="h-title">{{title}}</div>
    <div v-html="content" class="content"></div>
  </div>
</template>
<script>
  import https from '../../https.js'
  export default {
    data() {
      return {
        content: '',
		title: "",
      }
    },
    created() {
      this.getContent()
    },
    methods: {
      getContent() {
        https.fetchGet('/api/cms/article/byCode/app-yszc').then(res => {
          console.log(res)
          const {
            data
          } = res;
          if (data.code == 200) {
            this.content = data.data.content
			this.title = data.data.title
			
          }
          // var data = res.data.data;
          // this.title = data.title;
          // this.detail = data.detail;
          // this.addDate = data.addDate;
          // this.viewCount = data.viewCount

        }).catch(err => {
          console.log(err)
          // Toast.loading({
          // 	message: err.data.data,
          // 	forbidClick: true,
          // })

        })


        // this.axios.get("https://dev.kypro.ikaoyaner.com/api/cms/article/byCode/app-yszc").then(res => {
        //   const { data } = res
        //   if(data.code == 200) {
        //     this.content = data.data.content
        //   }
        // })
      }
    }
  }
</script>
<style lang="less" scoped>
  .container {
    .h-title {
      padding: 20px 12px 10px 12px;
      font-size: 24px;
      color: #444;
      border-bottom: 1px solid #ebebeb;
    }

    .content {
      padding: 0 12px;

      a {
        font-size: 20px;
      }
    }
  }

  .container /deep/ a {
    font-size: 17px;
    color: #333333;
  }

  .container /deep/ .clause,
  .container /deep/ .title {
    font-size: 18px;
  }
</style>
